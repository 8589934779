<script setup>
import { onMounted } from 'vue';
import { gsap } from 'gsap';
import Parallax from 'parallax-js';

window.addEventListener('loading-end', () => {
  gsap.from(['.homepage__background', '#nav-header'], {
    duration: 1.5,
    opacity: 0
  });
});

onMounted(() => {
  const scene = document.querySelector('.homepage__parallax');
  // eslint-disable-next-line no-unused-vars
  const parallaxInstance = new Parallax(scene, {});
});
</script>

<template>
  <div class="homepage__container">
    <div class="homepage__moon">
      <div class="homepage__background--common homepage__background--moon"></div>
    </div>
    <div class="homepage__background">
      <div class="homepage__parallax">
        <div class="homepage__background--common homepage__background--floor" data-depth="0.1"></div>
        <div class="homepage__background--common homepage__background--stair" data-depth="0.2"></div>
        <div class="homepage__background--common homepage__background--role" data-depth="0.4"></div>
      </div>
      <div class="homepage__text">‘The unprecedented spread’ -> <br />‘Oni Club Contagion’</div>
    </div>
  </div>
</template>

<style lang="less">
.homepage {
  &__container {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 980;
    font-family: 'FreckleFace';
  }
  &__moon {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 400vh;
  }
  &__background {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    &--common {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-position: bottom;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
    }
    &--moon {
      background-image: url('../assets/homepage/moon.png');
      background-position: center;
      background-size: cover;
      position: sticky;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
    }
    &--stair {
      background-image: url('../assets/homepage/stair.png');
      background-position: center;
      background-size: cover;
    }
    &--floor {
      background-image: url('../assets/homepage/floor.png');
      background-size: contain;
    }
    &--role {
      left: 50%;
      transform: translateX(-50%);
      background-image: url('../assets/homepage/role.png');
      background-size: 30vh, contain;
    }
  }
  &__text {
    position: absolute;
    bottom: 36px;
    left: 36px;
    font-size: 75px;
    font-weight: 400;
    line-height: 74px;
    letter-spacing: -0.025em;
    text-align: left;
    color: #FFFFFF;
    white-space: pre-line;
  }
}
</style>
