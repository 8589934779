<script setup>
import { onMounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  const { clientHeight } = document.documentElement;
  gsap.to('#about-us__canvas', {
    duration: 0.5,
    position: 'absolute',
    xPercent: -50,
    yPercent: -50,
    left: '50%',
    top: '50%',
    height: clientHeight,
    scrollTrigger: {
      trigger: '.about-us__placeholder--scale',
      start: 'top top',
      end: 'bottom bottom',
      scrub: 1
    }
  });

  const canvas = document.getElementById('about-us__canvas');
  const ctx = canvas.getContext('2d');
  const frameCount = 61;
  const currentFrame = (index) => `/frames/onibite-frame-${index}.png`;

  const drawImage = (img) => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imgWidth = img.width;
    const imgHeight = img.height;

    // 计算图片缩放比例
    const scale = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight);

    // 计算绘制后的图片大小和位置
    const drawWidth = imgWidth * scale;
    const drawHeight = imgHeight * scale;
    const x = (canvasWidth - drawWidth) / 2;
    const y = (canvasHeight - drawHeight) / 2;

    ctx.drawImage(img, x, y, drawWidth, drawHeight);
  };

  const preloadImages = () => {
    for (let i = 1; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
    }
  };

  const img = new Image();
  img.src = currentFrame(61);
  img.onload = function () {
    drawImage(img);
  };

  const updateImage = (index) => {
    index = 62 - index;
    img.src = currentFrame(index);
    drawImage(img);
  };

  const placeholderFrameEl = document.querySelector('.about-us__placeholder--frame');
  const placeholderFrameHeight = placeholderFrameEl.clientHeight;
  window.addEventListener('scroll', () => {
    let top = placeholderFrameEl.getBoundingClientRect().top;
    if (top > 0) {
      return;
    }

    top = -top;
    const scrollFraction = top / placeholderFrameHeight;
    const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));

    requestAnimationFrame(() => updateImage(frameIndex + 1));
  });

  preloadImages();
});
</script>

<template>
  <div class="about-us">
    <div class="about-us__wrapper">
      <div class="about-us__content">
        <span>Welcome to the Oni Club Contagion, a realm where the allure of the enigmatic </span>
        <canvas id="about-us__canvas" class="about-us__canvas" width="900" height="500"></canvas>
        <span style="color: #db1d1e"> Oni </span>
        <span>spreads like wildfire throughout the Metaverse. With </span>
        <img style="width: 2vw; vertical-align: sub;" src="../assets/about-us/icon00000.png" alt="0">
        <span> each new member </span>
        <img style="width: 3vw; vertical-align: middle;" src="../assets/about-us/icon00007.png" alt="7">
        <span> the contagion intensifies</span>
      </div>
    </div>
    <div class="about-us__placeholder--scale"></div>
    <div class="about-us__placeholder--frame"></div>
  </div>
</template>

<style lang="less">
.about-us {
  position: relative;
  z-index: 1000;
  width: 100vw;
  height: 500vh;
  font-family: 'FreckleFace';
  &__wrapper {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    font-size: 3.5vw;
    color: #fff;
    text-align: center;
    padding: 0 12.5vw;
  }
  &__content {
    transition: all .5s linear;
  }
  &__canvas {
    height: 6vw;
    vertical-align: middle;
    top: 42%;
    left: 56%;
  }
  &__placeholder {
    &--scale {
      position: absolute;
      top: 0;
      height: 200vh;
      width: 1px;
    }
    &--frame {
      position: absolute;
      top: 100vh;
      height: 300vh;
      width: 1px;
    }
  }
}
</style>
