<script setup>
import { ref } from 'vue';

const jumps = [
  { link: '#home', label: 'HOME' },
  { link: '#about-us', label: 'ABOUT US' },
  { link: '#roadmap', label: 'ROADMAP' }
  // { link: '#faq', label: 'FAQ' }
];
function omJump (jump) {
  const { link } = jump;
  document.querySelector(link).scrollIntoView({ behavior: 'smooth' });
}

const links = [
  //  { link: 'https://discord.gg/oni-bite', image: require('../assets/header/discord.png') },
  { link: 'https://twitter.com/oni_bite', image: require('../assets/header/twitter.png') }
  // { link: '', image: require('../assets/header/ig.png') },
  // { link: '', image: require('../assets/header/telegram.png') }
];
function onClickLink (link) {
  window.open(link.link, '_blank');
}

const music = window.bgmusic;
const musicIconList = [require('../assets/header/music-on.png'), require('../assets/header/music-off.png')];
const musicIcon = ref(musicIconList[0]);
window.addEventListener('loading-end', () => {
  musicIcon.value = music.isPlaying ? musicIconList[0] : musicIconList[1];
});
function onClickMusic () {
  if (music.isPlaying) {
    musicIcon.value = musicIconList[1];
    music.onPauseAudio();
  } else {
    musicIcon.value = musicIconList[0];
    music.onPlayAudio();
  }
}
</script>

<template>
  <div id="nav-header" class="header__container">
    <div class="header__left">
      <img class="header__left--logo" src="../assets/header/logo.svg" alt="logo">
      <div class="header__left--jumps">
        <div class="pointer" v-for="jump in jumps" :key="jump.label" @click="omJump(jump)">{{ jump.label }}</div>
      </div>
    </div>
    <div class="header__right">
      <img class="header__right--icon pointer" :src="musicIcon" alt="link" @click="onClickMusic">
      <img class="header__right--icon pointer" v-for="link in links" :key="link.link" :src="link.image" alt="link" @click="onClickLink(link)">
    </div>
  </div>
</template>

<style lang="less">
.header__container {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 46px 26px 0 26px;
  z-index: 1990;
}
.header__left {
  display: flex;
  &--logo {
    margin-right: 16px;
    filter: drop-shadow(2px 4px 6px black);
  }
  &--jumps {
    display: flex;
    gap: 6px;
    padding: 8px 12px;
    align-items: center;
    border-radius: 18px;
    background: rgba(20, 20, 20, 0.19);
    backdrop-filter: blur(5.5px);
    & > div {
      color: white;
      font-size: 12px;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      border-radius: 12px;
      border: 1px solid #FFF;
      display: flex;
      padding: 12px 16px;
      align-items: flex-start;
      gap: 10px;
      white-space: nowrap;
    }
  }
}
.header__right {
  &--icon {
    margin-left: 4px;
    width: 44px;
  }
}

@media screen and (max-width: 900px) {
  .header__container {
    padding: 20px 12px 0 12px;
  }
  .header__left {
    &--logo {
      width: 24px;
      margin-right: 4px;
    }
    &--jumps {
      gap: 2px;
      padding: 2px 4px;
      border-radius: 12px;
      & > div {
        font-size: 12px;
        border-radius: 8px;
        padding: 4px 8px;
        gap: 4px;
      }
    }
  }
  .header__right {
    &--icon {
      margin-left: 12px;
      width: 20px;
    }
  }
}
</style>
