<script setup>
import { onMounted } from 'vue';
import Parallax from 'parallax-js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  gsap.from(['#footer__image--red', '.faq__container--bg'], {
    opacity: 0,
    scrollTrigger: {
      trigger: '#footer__image--red',
      start: 'top+=100px bottom',
      end: 'bottom bottom',
      scrub: 0.5
    }
  });

  const scene = document.querySelector('.footer__image');
  // eslint-disable-next-line no-unused-vars
  const parallaxInstance = new Parallax(scene, {});
});

const year = new Date().getFullYear();

function onJumpToTop () {
  const el = document.querySelector('body');
  el.scrollIntoView({ behavior: 'smooth' });
}
</script>

<template>
  <div class="nav-footer">
    <div class="footer__image">
      <div id="footer__image--red" class="footer__image footer__image--1"></div>
      <div class="footer__image footer__image--2"></div>
      <div class="footer__image footer__image--3" data-depth="0.1"></div>
      <div class="footer__image footer__image--4" data-depth="0.2"></div>
    </div>
    <div class="footer__box">
      <img class="footer__box--logo" src="../assets/header/logo.svg" alt="logo">
      <div class="footer__box--remark">
        <span>@{{year}} ONI BITE</span>
        <span>The Unprecedented Spread</span>
        <span class="pointer" @click="onJumpToTop">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9"/>
            <path d="M16.3536 6.64645C16.1583 6.45119 15.8417 6.45119 15.6464 6.64645L12.4645 9.82843C12.2692 10.0237 12.2692 10.3403 12.4645 10.5355C12.6597 10.7308 12.9763 10.7308 13.1716 10.5355L16 7.70711L18.8284 10.5355C19.0237 10.7308 19.3403 10.7308 19.5355 10.5355C19.7308 10.3403 19.7308 10.0237 19.5355 9.82843L16.3536 6.64645ZM16.5 26L16.5 7L15.5 7L15.5 26L16.5 26Z" fill="#D9D9D9"/>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.nav-footer {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.footer__image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  &--1 {
    background-image: url("../assets/footer/001.png");
  }
  &--2 {
    background-image: url("../assets/footer/002.png");
  }
  &--3 {
    background-image: url("../assets/footer/003.png");
  }
  &--4 {
    background-image: url("../assets/footer/004.png");
  }
}
.footer__box {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  &--remark {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D9D9D9;
    margin-top: 16px;
    padding-top: 16px;
    text-transform: uppercase;
    color: #FFF;;
    font-size: 12px;
  }
}
</style>
