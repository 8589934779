<script setup>
</script>

<template>
  <div class="faq__container">
    <div class="faq__container--bg"></div>
    <div class="faq__inner">
      <div class="faq__card faq__card--1">
        <img class="faq__card--img faq__card--image" src="../assets/faq/1-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/1-desc.png" alt="1">
      </div>
      <div class="faq__card faq__card--2">
        <img class="faq__card--img faq__card--image" src="../assets/faq/2-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/2-desc.png" alt="1">
      </div>
      <div class="faq__card faq__card--3">
        <img class="faq__card--img faq__card--image" src="../assets/faq/3-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/3-desc.png" alt="1">
      </div>
      <div class="faq__card faq__card--4">
        <img class="faq__card--img faq__card--image" src="../assets/faq/4-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/4-desc.png" alt="1">
      </div>
      <div class="faq__card faq__card--5">
        <img class="faq__card--img faq__card--image" src="../assets/faq/5-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/5-desc.png" alt="1">
      </div>
      <div class="faq__card faq__card--6">
        <img class="faq__card--img faq__card--image" src="../assets/faq/6-image.png" alt="1">
        <img class="faq__card--img faq__card--desc" src="../assets/faq/6-desc.png" alt="1">
      </div>
    </div>
  </div>
</template>

<style lang="less">
.faq__container {
  position: relative;
  height: 63vw;
  width: 100vw;
  box-sizing: border-box;
  padding: 5vw 5vw;
  &--bg {
    position: absolute;
    top: 0;
    left: -5%;
    width: 105%;
    height: 100%;
    background-image: url("../assets/footer/001.png");
    background-position: center;
    background-size: cover;
  }
}
.faq__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.faq__card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &--1 {
    clip-path: polygon(0 0, 46% 0, 48% 51%, 0 63%);
  }
  &--2 {
    clip-path: polygon(0% 64%, 48% 51%, 49% 68%, 0% 86%);
  }
  &--3 {
    clip-path: polygon(0 87%, 49% 68%, 50% 100%, 0 100%);
  }
  &--4 {
    clip-path: polygon(48% 0, 61% 0, 63% 47%, 49% 51%);
  }
  &--5 {
    clip-path: polygon(49% 51%, 63% 47%, 66% 100%, 51% 100%);
  }
  &--6 {
    clip-path: polygon(62% 0, 97% 0, 97% 100%, 67% 100%);
  }
  &--img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform .6s ease, filter .6s ease;
  }
  &--desc {
    filter: blur(10px);
    opacity: 0;
  }
  &:hover {
    .faq__card--desc {
      filter: blur(0px);
      opacity: 1;
    }
  }
}

</style>
