<script setup>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { onMounted } from 'vue';

import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

onMounted(() => {
  const container = document.getElementById('thing-gallery');

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);

  const scene = new THREE.Scene();
  scene.background = null;

  const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 100);
  camera.position.set(5, 2, 8);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(0, 0, 0);
  controls.update();
  controls.enableZoom = false;
  controls.enablePan = false;
  controls.enableDamping = true;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.5;
  controls.rotateSpeed = 0.05;

  const loader = new GLTFLoader();
  loader.load('/oniball/oniball.glb', function (gltf) {
    const model = gltf.scene;

    model.traverse((child) => {
      if (child.isMesh) {
        child.material.emissive = child.material.color;
        child.material.emissiveMap = child.material.map;
      }
    });

    model.position.set(0, 0, 0);
    model.scale.set(0.8, 0.8, 0.8);
    scene.add(model);
    animate();
  }, undefined, function (e) {
    console.error(e);
  });

  window.onresize = function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  function animate () {
    requestAnimationFrame(animate);
    controls.update();
    renderer.render(scene, camera);
  }
});
</script>

<template>
  <div id="thing-gallery" class="thing-gallery"></div>
</template>

<style lang="less">
.thing-gallery {
  position: relative;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}
</style>
